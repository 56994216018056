<template>
	<header>
		<div class="left">
			<div class="logo">
				<img src="@/assets/img/logo.png" />
				<p>TradeDo</p>
			</div>
			<nav v-if="isAuthenticated">
				<router-link to="/" exact-active-class="current">Home</router-link>
				<router-link to="/subscription" exact-active-class="current">Subscription</router-link>
				<router-link to="/settings" exact-active-class="current">Settings</router-link>
				<!-- <router-link to="/extension" exact-active-class="current">Extension</router-link> -->
				<a href="/TradeDoEx.zip" target="_blank">Extension</a>
			</nav>
		</div>
		<div class="right">
			<button
				class="theme"
				data-theme="light"
				@click="setLightTheme"
				v-if="theme == 'dark'"
			>
				<img src="@/assets/img/moon-light.svg" />
			</button>
			<button
				class="theme"
				data-theme="dark"
				@click="setDarkTheme"
				v-if="theme == 'light'"
			>
				<img src="@/assets/img/moon-dark.svg" />
			</button>
			<button class="logout" @click="logout" v-if="isAuthenticated">Logout</button>
		</div>
	</header>
</template>

<script>
import { mapState } from "vuex";

export default {
	name: "HeaderView",

	computed: {
		...mapState(["isAuthenticated", "theme"]),
	},

	mounted() {},

	methods: {
		setLightTheme() {
			this.$store.dispatch("setTheme", { theme: "light" });
		},
		setDarkTheme() {
			this.$store.dispatch("setTheme", { theme: "dark" });
		},
		logout() {
			this.$store.dispatch("logout");
			this.$router.push("/login");
		},
	},
};
</script>

<style scoped>
header {
	padding-top: 54px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
header .left {
	display: flex;
	align-items: center;
	gap: 93px;
}

header .right {
	display: flex;
	align-items: center;
	gap: 59px;
}

.logo {
	display: flex;
	align-items: center;
	gap: 10px;
}

.logo img {
	width: 48px;
}

.logo p {
	color: #15a3ff;
	font-size: 28px;
	font-weight: 700;
}

header nav {
	display: flex;
	align-items: center;
	gap: 45px;
}

header nav a {
	font-size: 16px;
	color: var(--nav-link);
	padding-bottom: 2px;
	border-bottom: 1px solid transparent;
}

header nav a.current {
	border-bottom: 1px solid #15a3ff;
}

.logout {
	width: 202px;
	height: 40px;
	border-radius: 3px;
	background: #1a1f2f;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #fff;
}
</style>
