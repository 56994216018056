import router from "@/router";
import server from "@/misc/server";

export async function authorize(username, password) {
	try {
		var data = await server.post("/auth/login", {
			username: username,
			password: password,
		});
		data.success = true;
		return data;
	} catch (e) {
		return {
			success: false,
			message: e.response?.data.message
				? e.response.data.message
				: e.message,
		};
	}
}

export async function register(username, password) {
	try {
		var data = await server.post("/auth/register", {
			username: username,
			password: password,
		});
		data.success = true;
		return data;
	} catch (e) {
		return {
			success: false,
			message: e.response?.data.message
				? e.response.data.message
				: e.message,
		};
	}
}

export function checkToken(store) {
	const token = localStorage.getItem("token");

	if (!token) {
		store.dispatch("logout");
		router.push("/login");
		store.commit("finishInitialization");
		return;
	}

	store.commit('setToken', {token: token });

	server
		.get("/auth/validate")
		.then((data) => {
			if (data.valid) {
				store.commit("setAuth", {
					isAuthenticated: true,
					token: token,
					username: data.username,
					admin: data.admin
				});
				if (router.currentRoute.value.meta.loginView) {
					router.push("/");
				}
				store.commit("finishInitialization");
			} else {
				store.dispatch("logout");
				router.push("/login");
				store.commit("finishInitialization");
			}
		})
		.catch((error) => {
			console.error("Error validating token:", error);
			router.push("/");
			store.commit("finishInitialization");
		});
}
