import { createStore } from "vuex";
import { authorize, register } from "./services/authentication";

export default createStore({
	state: {
		isAuthenticated: false,
		token: "",
		username: "",
		admin: false,
		intialized: false,
		loading: true,
		notificationMessage: "",
		notificationType: "info",
		notificationTimeout: null,
		theme: "",
		currentApp: localStorage.getItem("current-app"),

		apps: [
			{
				id: 730,
				name: 'Counter-Strike 2',
				icon: require('@/assets/img/cs.svg')
			},
			{
				id: 570,
				name: 'Dota 2',
				icon: require('@/assets/img/dota.svg')
			},
			{
				id: 440,
				name: 'Team Fortress 2',
				icon: require('@/assets/img/tf.svg')
			},
		]

	},
	mutations: {
		setToken(state, { token }) {
			state.token = token;
		},
		setAuth(state, { isAuthenticated, token, username, admin }) {
			state.isAuthenticated = isAuthenticated;
			state.token = token;
			state.admin = admin;
			state.username = username;
			state.loading = false;
		},
		clearAuth(state) {
			state.isAuthenticated = false;
			state.token = null;
			state.loading = false;
		},
		showLoader(state) {
			state.loading = true;
		},
		hideLoader(state) {
			state.loading = false;
		},
		finishInitialization(state) {
			state.intialized = true;
			state.loading = false;
		},
		setNotification(state, {text, type}) {
			state.notificationMessage = text;
			state.notificationType = type;
			if(state.notificationTimeout) {
				clearTimeout(state.notificationTimeout);
			}
			state.notificationTimeout = setTimeout(() => {
				if (state.notificationMessage == text) {
					state.notificationMessage = "";
				}
			}, 3000);
		},
		setTheme(state, theme) {
			state.theme = theme;
			document.documentElement.setAttribute("data-theme", theme);
		},
		setCurrentApp(state, app) {
			state.currentApp = app;
		},
	},
	actions: {
		async login({ commit }, { username, password }) {
			const authResult = await authorize(username, password);
			if (!authResult.success) {
				throw new Error(authResult.message);
			}
			localStorage.setItem("token", authResult.token);
			localStorage.setItem("user", JSON.stringify({username: username, admin: authResult.admin}));
			commit("setAuth", {
				isAuthenticated: true,
				token: authResult.token,
				username: username,
				admin: authResult.admin
			});
		},
		async signup({ commit }, { username, password }) {
			const authResult = await register(username, password);
			if (!authResult.success) {
				throw new Error(authResult.message);
			}
			localStorage.setItem("token", authResult.token);
			localStorage.setItem("user", JSON.stringify({username: username, admin: authResult.admin}));
			commit("setAuth", {
				isAuthenticated: true,
				token: authResult.token,
				username: username,
				admin: authResult.admin
			});
		},
		logout({ commit }) {
			// Clear the authentication state
			localStorage.removeItem("token");
			localStorage.removeItem("user");
			commit("clearAuth");
		},
		showNotification({ commit }, { text, type }) {
			commit("setNotification", {text, type});
		},
		setTheme({ commit }, { theme }) {
			commit("setTheme", theme);
			localStorage.setItem("theme", theme);
		},
		initTheme({ commit }) {
			var theme = localStorage.getItem("theme");
			if (theme) {
				commit("setTheme", theme);
			} else if (
				window.matchMedia &&
				window.matchMedia("(prefers-color-scheme: dark)").matches
			) {
				commit("setTheme", "dark");
			} else {
				commit("setTheme", "light");
			}
		},
		initApp({commit}) {
			var app = parseInt(localStorage.getItem("current-app"));
			if(isNaN(app)) app = 730;
			commit("setCurrentApp", app);
		},
		selectApp({ commit }, { app }) {
			commit("setCurrentApp", app);
			localStorage.setItem("current-app", app);
		},
	},
	getters: {
		isAuthenticated: (state) => state.isAuthenticated,
		getToken: (state) => state.token,
	},
});
